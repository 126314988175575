import React from 'react'
import { useAuth } from 'contexts/AuthContext'
import styled from 'styled-components'
import Link from 'next/link'

import InitLoader from 'components/ui/InitLoader'
import Login from 'components/Login'

export default function UserArea({ children }){

  const {
    loading, hasUser, logout, user
  } = useAuth()

  return <>
    <InitLoader visible={!!loading} />
    { hasUser() ? <div>
      <button onClick={logout}>Logout</button>
      <div>
        {children}
      </div>
    </div> : <Login /> }
  </>


}
