import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const pulse = keyframes`
  0%{
    transform: scale3d(0, 0, 1);
  }
  40%{
    transform: scale3d(1, 1, 1);
  }
  60%{
    transform: scale3d(1, 1, 1);
  }
  100%{
    transform: scale3d(0, 0, 1);
  }
`

const pulse2 = keyframes`
  0%{
    transform: scale3d(0, 0, 1);
  }
  20%{
    transform: scale3d(1, 1, 1);
  }
  80%{
    transform: scale3d(1, 1, 1);
  }
  100%{
    transform: scale3d(0, 0, 1);
  }
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top:0;
  left:0;
  background: #ffffff;
  opacity: ${p => p.visible ? 1 : 0};
  transition: opacity 500ms ease;
  z-index: 1000;

  .red{
    position: absolute;
    top: calc(50% - 27.5px);
    left: calc(50% - 27.5px);
    width: 55px;
    height: 55px;
    background-color: red;
    border-radius: 50%;
    animation: ${pulse2} 2000ms ease-in-out infinite;
  }

  .white{
    width: 21px;
    height: 21px;
    position: absolute;
    top: calc(50% - 10.5px);
    left: calc(50% - 10.5px);
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #eaeaea;
    animation: ${pulse} 1.5s ease-in-out infinite;

    span{
      animation: ${rotate} 1s ease-in-out infinite;
    }
  }


`

export default function InitLoader({ visible = true }){

  const [ exists, setExists ] = useState(true)

  useEffect(() => {
    visible && !exists ? setExists(true) : null
    !visible && exists ? setTimeout(() => setExists(false), 500) : null
  },[ visible ])

  return exists ? <Container visible={!!visible}>
    <div className="red"></div>
    <div className="white"><span>&times;</span></div>
  </Container> : null

}
