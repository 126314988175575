import React, { useState } from 'react'
import styled from 'styled-components'
import Image from 'next/image'
import Logo from 'components/ui/Logo'
import Button from 'components/ui/Button'

import { useAuth } from 'contexts/AuthContext'
import LoadingIcon from 'components/ui/LoadingIcon'
import EmailIcon from '@material-ui/icons/Email';

const Container = styled.div`

  position: fixed;
  top:0;
  left:0;
  width: 100%;
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255,255,255,0.8);

  .box{
    display: inline-block;
    background: #fbfbfb;
    padding: 21px;
    border-radius: 13px;

    .hasNextLink{
      max-width: 300px;
      text-align: center;
    }

    .error{
      color: ${p => p.theme.danger};
    }

    div.error{
      max-width: 300px;
      text-align: center;
    }

    .buttons{
      margin-top: 13px;
      button{
        margin-top: 21px;
        background: white;
        color: #676767;

        &.Mui-disabled{
          color: #989898;
        }
      }
    }

  }

`

export default function Login(){

  const {
    loginWithFacebook,
    loginWithGoogle,
    hasNextLink,
    needVerification,
    loggingIn,
    authError,
    sendVerification
  } = useAuth()

  const [ emailSent, setEmailSent ] = useState(false)
  const [ emailError, setEmailError ] = useState(null)
  const sendEmailVerification = async () => {
    setEmailError(null)
    setEmailSent(null)
    try{
      await sendVerification()
      setEmailSent(true)
    }catch(e){
      console.log(e)
      setEmailError(e)
    }
  }

  return <Container>
    <div className="box">
      <Logo />
      <div className="buttons">

        { authError ? <div className="error">
          <p><b>Login mengalami kendala, mohon coba beberapa menit lagi</b></p>
        </div> : null}

        { needVerification ? <div className="hasNextLink">
          <p>Mohon verifikasi email anda untuk melanjutkan.</p>

          { emailError ? <p className="error">Pengiriman email verifikasi mengalami kendala</p> : null}

          { emailSent ? <>
            <p>
              Kami telah mengirim email ke <i>{needVerification.email}</i>.
              Klik link yang tertera di email untuk melanjutkan..
            </p>
            <p className="small"><small>Anda tidak menerima email verifikasi?</small></p>
          </> : null }

          <Button
            fullWidth
            variant="outlined"
            color="default"
            className="verify"
            size="large"
            onClick={sendEmailVerification}
            loader
            startIcon={<EmailIcon />}
            >{emailSent ? 'Kirim lagi' : 'Kirim Email Verifikasi sekarang'}</Button>

        </div> : null}

        { hasNextLink && hasNextLink.email ? <div className="hasNextLink">
          <p>Anda telah login di ArcherReady dengan email ini sebelumnya.</p>
          <p>Mohon login kembali dengan provider yang sesuai, agar kami dapat
          menghubungkan akun ini, dengan akun anda sebelumnya..</p>
        </div> : null}

        { hasNextLink && !hasNextLink.email ? <div className="hasNextLink">
          <p>Akun anda tidak mempunyai email.</p>
          <p>Mohon login kembali dengan email, agar kami dapat
          menghubungkan akun ini, dengan email anda..</p>
        </div> : null}

        { loggingIn ?  <LoadingIcon size={55} color={'#eaeaea'} /> : needVerification ? null : <>

          { hasNextLink && hasNextLink.g ? null : <><Button
            fullWidth
            variant="outlined"
            color="default"
            className="google"
            size="large"
            onClick={loginWithGoogle}
            loader
            startIcon={<Image width={24} height={24} src="/icons/google.svg" />}
            ><span>Login dengan Google</span>
          </Button><br /></> }

          { hasNextLink && hasNextLink.f ? null : <Button
            fullWidth
            variant="outlined"
            color="default"
            className="facebook"
            size="large"
            onClick={loginWithFacebook}
            loader
            startIcon={<Image width={24} height={24} src="/icons/facebook.svg" />}
            ><span>Login dengan Facebook</span>
          </Button> }

        </>}

        {/* <button onClick={logout}>logout</button> */}
      </div>
    </div>
  </Container>

}
