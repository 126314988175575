import React from 'react'
import UserArea from 'components/UserArea'

import { useAuth } from 'contexts/AuthContext'

import { GET } from 'lib/request'

export default function Home() {

  const { user } = useAuth()

  const apiCall = async () => {
    await GET('/me')
  }

  return <UserArea>
    <pre>{JSON.stringify(user, null, 2)}</pre><br />
    <button onClick={apiCall}>API Call</button>
    <br />
  </UserArea>
}
