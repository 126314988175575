import React from 'react'
import styled, { keyframes } from 'styled-components'
import DataUsageIcon from '@material-ui/icons/DataUsage';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`

  display: flex;
  justify-content: center;
  align-items: center;

  svg{
    color: ${p => p.color || p.theme.logo};
    font-size: ${p => `${p.size||24}px`};
    animation: ${rotate} 1s ease-in-out infinite;
  }

`

export default function LoadingIcon({ size, color }){

  return <Container color={color} size={size}>
    <DataUsageIcon />
  </Container>

}
