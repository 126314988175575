import React, { useState } from 'react'
import MuiButton from '@material-ui/core/Button'
import LoadingIcon from 'components/ui/LoadingIcon'

export default function Button({ children, onClick, loader, startIcon, endIcon, ...props }){

  const [ loading, setLoading ] = useState(false)
  const clickHandlerAsync = async (e) => {
    setLoading(true)
    await onClick(e)
    setLoading(false)
  }

  return <MuiButton {...props}
    startIcon={ loading ? null : startIcon}
    endIcon={ loading ? null : endIcon}
    onClick={loader ? clickHandlerAsync : onClick}
    >{loading ? <LoadingIcon /> : children }</MuiButton>

}
