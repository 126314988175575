import React from 'react'
import styled from 'styled-components'

const Container = styled.div`

  display: flex;
  justify-content: center;
  align-items: center;

  img{
    width: 55px;
    height: 55px;
  }

  span{
    font-family: 'Racing Sans One', cursive;
    padding: 0;
    margin-left: 8px;
    font-size: 34px;
    font-weight: 600;
  }

`

export default function Logo(){

  return <Container>
    <img src="/ArcherReady.png" />
    <span>ArcherReady</span>
  </Container>

}
